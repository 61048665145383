import React from "react"
import { useRouteError } from "react-router-dom"
import Layout from "../components/Layout"
import { FaExclamationTriangle } from 'react-icons/fa'
import { Helmet } from "react-helmet-async"

import { Link } from 'react-router-dom'
import Links from "../components/Links"

export default function ErrorRouter() {
  const error = useRouteError()
  return (
    <>
      <Helmet>
        <title>Ошибка клиента</title>
        <meta
          name="description"
          content="Ошибка на сторонк клиента"
        />
      </Helmet>
      <Layout>
        <Links  home={true} />
        <div className="flex flex-col items-center justify-center h-full gap-2 text-xl">
          <FaExclamationTriangle className="text-7xl text-red-500" />
          <p>{error.statusText || error.message}</p>
          <Link className="text-blue-500 cursor-pointer" to="/">
            Вернуться на главную
          </Link>
        </div>
      </Layout>
    </>
  )
}
