import React from "react"
import ClientPriceBlock from "./ClientPriceBlock"

export default function ClientOptionsPrice({
 optionValues,
  level,
  saveValues,
  product,
crumbs
}
 
) {
  
  if (!level && optionValues.hasOwnProperty("price")) {
    return (
      <div className="flex  justify-end">
        <ClientPriceBlock
          product={product}
          optionValues={optionValues}
          saveValues={saveValues}
        />
      </div>
    )
  } else {
    return (
      <div>
        {Object.keys(optionValues).map((option) => (
          <div key={`${level}${option}${crumbs.join("")}`}>         
            {optionValues[option].hasOwnProperty("price") ? (
              <div className="flex justify-between items-center option pr-2 py-1 overflow-x-auto">
                <div
                  style={{
                    "--dynamic-padding": `${(level + 1) * 20}px`,
                    "--dynamic-padding-sm": `${(level + 1) * 10}px`,
                  }}
                  className="pl-[var(--dynamic-padding-sm)] sm:pl-[var(--dynamic-padding)]"
                >
                  {option}
                </div>
                <ClientPriceBlock
                  product={product}
                  optionValues={optionValues[option]}
                  saveValues={saveValues}
                />
              </div>
            ) : (
              <>
                <div
                  style={{
                    "--dynamic-padding": `${(level + 1) * 20}px`,
                    "--dynamic-padding-sm": `${(level + 1) * 10}px`,
                  }}
                  className=" option py-1 flex items-center pl-[var(--dynamic-padding-sm)] sm:pl-[var(--dynamic-padding)]"
                >
                  {option}
                </div>

                <ClientOptionsPrice
                  level={level + 1}
                  product={product}
                  optionValues={optionValues[option]}
                  saveValues={saveValues}
                  crumbs={[...crumbs, option]}
                />
              </>
            )}
          </div>
        ))}
      </div>
    )
  }
}
