import { createContext, useEffect, useState } from "react"
import { localStorageService } from "../utils"

const ProductsContext = createContext()

export const ProductsProvider = ({ children }) => {
  const [currencyShop, setCurrencyShop] = useState("UAH")

  const [cart, setCart] = useState(   
    localStorageService.get('cart')||[]
  )

  useEffect(() => {    
    localStorageService.set('cart',cart)
  }, [cart])

  return (
    <ProductsContext.Provider
      value={{
        currencyShop,
        setCurrencyShop,
        cart,
        setCart,
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}

export default ProductsContext
