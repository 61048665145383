export const saveImage = async ({ file, ratio }) => {
  const formData = new FormData()
  formData.append("image", file)
  const res = await fetch(`/api/image?ratio=${ratio}`, {
    method: "POST",
    headers: {
      enctype: "multipart/form-data"     
    },
    body: formData,
  })
  const data = await res.json()

  if (!res.ok) {
    throw new Error(data.message)
  } else {
    return { ...data }
  }
}
