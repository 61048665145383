import styles from "../styles/Header.module.css"
import { Link } from "react-router-dom"
import { useContext } from "react"
import ProductsContext from "../context/ProductsContext"
import { PHONE1, PHONE2 } from "../config"

import { FaCircle, FaPhone, FaShoppingCart, FaSyncAlt } from "react-icons/fa"

import { getQntInCart } from "../utils/index"
import { useCurrencyRate } from "../swr"
import Search from "./header/Search"
import Select from "./header/Select"
import NavMenu from "./header/NavMenu"

export default function Header() {
  const currencyRate = useCurrencyRate()
  const { cart } = useContext(ProductsContext)
  return (
    <div
      className={`${styles.container} grid grid-cols-4 grid-rows-3 
       md:grid-cols-[2fr_3fr_2fr] md:grid-rows-[3fr_2fr] gap-x-2 items-center h-[150px] bg-[#f2f3f4]  py-0 pr-4 pl-3`}
    >
      <div
        className={`${styles.logo} justify-self-center md:justify-self-start  hover:cursor-pointer active:scale-[0.97]`}
      >
        <Link to="/">
          <h1 className=" font-bold ">
            <span className=" text-red-500 md:text-4xl/none text-3xl/none">
              karmen
            </span>
            <span className="text-gray-600 md:text-3xl/none text-2xl/none">
              .in.ua
            </span>
          </h1>
        </Link>
      </div>
      <div
        className={`${styles.search} justify-self-center w-full  xl:w-[500px] max-w-full`}
      >
        <Search />
      </div>
      <div className={`${styles.nav} justify-self-start md:justify-self-end `}>
        <NavMenu />
      </div>
      <div
        className={`${styles.currencies} flex flex-row gap-x-1 justify-self-start text-base
         md:gap-0 md:flex-col  lg:gap-[10px] lg:flex-row`}
      >
        <div className="flex gap-x-1 items-center text-lg">
          <FaSyncAlt />
          <p>
            USD: {currencyRate ? currencyRate.USD.toFixed(2) : ""} EUR:{" "}
            {currencyRate ? currencyRate.EUR.toFixed(2) : ""}
          </p>
        </div>
      </div>
      <div
        className={`${styles.phones} hidden md_lg:flex justify-self-center items-center gap-x-3 text-xl`}
      >
        <div className="text-amber-700">
          <FaPhone />
        </div>
        <div className="md:flex gap-x-3  ">
          <Link to="tel: +380509501671">
            <p> {PHONE1} </p>
          </Link>
          <Link to="tel: +380982086083">
            <p></p> {PHONE2}
          </Link>
        </div>
      </div>
      <div className={`${styles.header_select} block md:hidden`}>
        <Select />
      </div>
      <div
        className={`${styles.cart_wrapper}  justify-self-end items-center text-4xl 
           flex gap-5`}
      >
        <div className="hidden md:block">
          <Select />
        </div>
        <Link to="/cart">
          <div className="relative cursor-pointer">
            <FaShoppingCart className="" />

            {cart && getQntInCart(cart) !== 0 ? (
              <>
                <p className=" absolute top-[43%] left-[60%] text-[#fff] text-xs z-50 -translate-x-1/2 -translate-y-1/2">
                  {cart && getQntInCart(cart) !== 0 ? getQntInCart(cart) : ""}
                </p>
                <div className="absolute top-0 -right-1 text-xs text-red-500">
                  <FaCircle />
                </div>
              </>
            ) : null}
          </div>
        </Link>
      </div>
    </div>
  )
}
