import React from 'react'
import { createPortal } from 'react-dom'

export default function ModalDialog({children}) {
  const content =
    <div className='fixed w-full h-screen top-0 left-0 flex justify-center
     items-center backdrop-blur-sm  bg-[rgb(0,0,0,0.3)] text-white'> 
      {children}
  </div>
  return  createPortal(content,document.body)
}
