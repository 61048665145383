import React from 'react'
import ModalDialog from './ModalDialog'

export default function HandleConfirm({title1,title2,handle}) {
  return (
    <ModalDialog>
      <div
        className="w-[350px] h-[200px] bg-slate-50 border border-gray-600 rounded-md shadow-md
       text-gray-700 grid  grid-cols-2 grid-rows-[120px_80px] items-center justify-items-center"
      >
        <div className=" col-span-2 justify-self-center self-center text-xl">
          <p className="text-center p-1">{title1} </p>
          <p className="text-center p-1">{title2}</p>
        </div>
        <div
          className="text-white text-2xl bg-red-500 px-7 py-2 rounded-md
           hover:cursor-pointer"
          onClick={() => handle(true)}
        >
         <p>Да</p> 
        </div>
        <div
          className="text-white text-2xl bg-emerald-700 px-7 py-2 rounded-md
           hover:cursor-pointer"
          onClick={() => handle(false)}
        >
        <p>Нет</p>  
        </div>
      </div>
    </ModalDialog>
  )
}
