
import { Helmet } from "react-helmet-async"
import Links from "../components/Links"
import MapFrame from "../components/MapFrame"

export default function Map() {
 
  return (
    <div className="flex flex-col gap-4 max-h-full h-full">
      <Helmet>
        <title>Карта</title>
        <meta name="description" content="Местоположение на карте магазина Кармен" />
      </Helmet>
      <Links back={true} home={true} />
      <div  className="flex-1">
      <MapFrame/>
      </div>
    </div>
  )
}
