import { Link } from "react-router-dom"
import { FaMapMarkerAlt } from "react-icons/fa"


export default function Footer() {
  return (
    <div className="h-full flex flex-col items-center justify-center gap-y-2 bg-[#333] text-[#fff]  text-center">
      <div className="w-full">
        <div className="flex  justify-around  text-xl max-w-[1500px] m-auto">
          <Link to="/information">
            <h2 className=" tracking-[0.3rem] hover:cursor-pointer">
              Информация
            </h2>          
          </Link>
       
          <Link to="/map">
            <div className=" hidden lg:flex items-center justify-center gap-x-2">
              <FaMapMarkerAlt />
              <h2>Карта</h2>
            </div>
          </Link>
          <div>
            <div>
              <Link to="/contacts">
                <h2 className=" tracking-[0.3rem] hover:cursor-pointer">
                  Контакты
                </h2>
              </Link>
            </div>         
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-gray-600 w-full"></div>
      <div className="  text-lg w-full">
        <p>Кармен &copy; 2021</p>
      </div>
    </div>
  )
}
