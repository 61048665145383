import {  API_URL, NOIMAGE } from "../config/index"
import { Link, useNavigate, useParams } from "react-router-dom"
import ProductsList from "../components/ProductsList"
import { useContext, useEffect, useState } from "react"
import { getArrayCatTree, idToString, localStorageService } from "../utils/index"
import Links from "../components/Links"
import CatalogsList from "../components/CatalogsList"
import { FaBorderAll, FaList, FaPencilAlt, FaTimes } from "react-icons/fa"
import { FaRegFolderOpen, FaFolderClosed } from "react-icons/fa6"
import {
  useCatalogsId,
  useFilteredCatalogs, 
  useProductsByCatalogId,
  useQntByCatalogId,
} from "../swr"

import { ToastContainer, toast } from "react-toastify"
import HandleConfirm from "../components/HandleConfirm"
import AuthContext from "../context/AuthContext"
import { useSWRConfig } from "swr"
import Spinner from "../components/Spinner"
import BrandContext from "../context/BrandContext"
import { Helmet } from "react-helmet-async"
import Pagination from "../components/Pagination"

export default function Catalog() {
  const { id } = useParams()
  const [isShowAsList, setIsShowAsList] = useState(() => {
      const data = localStorageService.get('catalog')
      const state =
      data && data.hasOwnProperty("isShowAsList") ? data.isShowAsList : false
    return state
  } )
  const { brand } = useContext(BrandContext)
  
  const { mutate } = useSWRConfig()
  const navigate = useNavigate()
  const {
    user: { isAdmin },
  } = useContext(AuthContext)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  // Флаг isShowProducts выставляется когда нужно показать товары всех детей каталога
  const [isShowProducts, setIsShowProducts] = useState(false)
  const [currentPage, setCurrentPage] = useState(() => {
  const data = localStorageService.get("catalog")
  return (data && data[id] ? data[id] : 1)
  })
      
   
  const productsPerPage = 12

  const qntProducts = useQntByCatalogId({
    id,
    brandId: brand._id ? brand._id : "",
  })

  const catalogs = useFilteredCatalogs(brand._id)
  const catalog = useCatalogsId(id)
  const childrenList =
    catalog && catalogs
      ? catalogs.filter(
          (item) => idToString(item.parentId) === idToString(catalog._id)
        )
      : []
  
  // for meta description
  const childrenLisrString = childrenList.length ? `Подкаталоги: ${childrenList.map(item=>item.name).join(', ')}` : ''
  
  const isFetch = catalogs && catalog && !childrenList.length
  const isForsed =
    catalogs && catalog && !!childrenList.length && isShowProducts

  const products = useProductsByCatalogId({
    id,
    brandId: brand._id ? brand._id : "",
    isFetch,
    isForsed: isForsed ? isForsed : "",
  })

  const productList = products
    ? products.sort((a, b) => (a.name > b.name ? 1 : -1))
    : []

  // for pagination
  const idxOfLastProduct = currentPage * productsPerPage
  const idxOfFirstProduct = idxOfLastProduct - productsPerPage
  const productsOnPage = productList.slice(idxOfFirstProduct, idxOfLastProduct)
  const numbers = []
    for (let i = 1; i <= Math.ceil(productList.length / productsPerPage); i++) {
      numbers.push(i)
    }

  useEffect(() => {
  localStorageService.update('catalog',{isShowAsList})
    
  },[isShowAsList])

  useEffect(() => {
    let data = localStorageService.get('catalog')    
    // предохранитель если окажется что номер в localStorage больше чем есть страниц
    if (data && data[id] && productList.length&&numbers.length < data[id]){
     data= localStorageService.delete('catalog',id)
    }      
    setCurrentPage(data&&data[id]?data[id]:1)    
  }, [id,productList.length,numbers.length])

  const handleDelete = async (rez) => {
    if (rez) {
      if (childrenList.length) {
        toast.warning("Обнаружены подкаталоги. Сначала удалите их")
        return
      }
      const res = await fetch(`/api/catalogs/${catalog._id}`, {
        method: "DELETE"      
      })
      const data = await res.json()
      if (!res.ok) {
        toast.error(data.message)
        return
      }
      mutate(`/api/catalogs/brand/${brand._id}`)
      navigate(-1)
    }
    setShowDeleteModal(false)
  }

  return (
    <>
      <ToastContainer />
      {catalog ? (
        <Helmet>
          <title>{catalog.name}</title>
          <meta name="description" content={`Каталог  ${catalog.name}. Товаров в каталоге ${qntProducts}. ${childrenLisrString}`} />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://www.schema.org",
              "@type": "catalog",
              name: catalog.name,
              image: [catalog.imageMd, catalog.imageSm].map(image=>`${API_URL}${image}`),
            })}
          </script>
        </Helmet>
      ) : null}
      <Links home={true} back={true}>
        <div className="flex   justify-between">
          <div className="flex items-center gap-x-4">
            <div className=" flex flex-wrap text-sb">
              {catalog && catalogs
                ? getArrayCatTree(catalog, catalogs).map((item, i, arr) => (
                    <div className="flex text-base text-sb" key={i}>
                      <Link to={`/catalog/${item._id}`}>
                        <p className=" cursor-pointer hover:text-blue-800">
                          {item.name}
                        </p>
                      </Link>
                      {i < arr.length - 1 ? (
                        <div className="px-1">➔</div>
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
            {isAdmin && catalog ? (
              <div className="hidden md:flex items-center gap-x-3">
                <FaPencilAlt
                  className="text-sb cursor-pointer text-xl"
                  onClick={() => navigate(`/edit_catalog/${catalog._id}`)}
                />
                <FaTimes
                  className="text-red-500 text-2xl cursor-pointer"
                  onClick={() => setShowDeleteModal(true)}
                />
              </div>
            ) : null}
          </div>
          {productList.length ? (
            <div
              className="flex justify-center items-center  gap-x-2 px-1
           text-2xl text-green-700"
            >
              <div
                className="cursor-pointer"
                title="Список"
                onClick={() => setIsShowAsList(true)}
              >
                <FaList />
              </div>
              <div
                className="cursor-pointer"
                title="Плитка"
                onClick={() => setIsShowAsList(false)}
              >
                <FaBorderAll />
              </div>
            </div>
          ) : null}
        </div>
      </Links>
      {catalog ? (
        <div className="flex flex-col gap-3 flex-1 pt-4">
          <div
            className="flex justify-center md:justify-start gap-4 h-[70px] items-center
              px-3 py-1 bg-neutral-100"
          >
            <img
              className="max-w-[100px] max-h-[66px]"
              src={
                catalog.imageSm ? `${catalog.imageSm}` : `${NOIMAGE}`
              }
              alt={catalog?catalog.name:''}
            />
            <div className="">
              <h1 className="text-2xl lg:text-3xl text-amber-900 inline">
                {catalog.name}{" "}               
              </h1>
              <span className="text-gray-600 text-xl">{`(${qntProducts} шт)`}</span>
            </div>

            <div className="flex-1 flex justify-end relative  text-4xl text-amber-900 ">
              {isShowProducts ? (
                <FaRegFolderOpen
                  className="cursor-pointer"
                  title="Скрыть тоывры этого каталога"
                  onClick={() => setIsShowProducts((prev) => !prev)}
                />
              ) : (
                <FaFolderClosed
                  className="cursor-pointer"
                  title="Показать все товары этого каталога"
                  onClick={() => setIsShowProducts((prev) => !prev)}
                />
              )}
            </div>
          </div>
          <div className="flex-1">
            {!isShowProducts && childrenList.length ? (
              <CatalogsList catalogs={childrenList} />
            ) : productList.length ? (
              <ProductsList
                products={productsOnPage}
                isShowAsList={isShowAsList}
              />
            ) : null}
          </div>
          {showDeleteModal ? (
            <HandleConfirm
              title1="Удалить каталог"
              title2={`${catalog.name}?`}
              handle={handleDelete}
            />
          ) : null}
          {productList.length ? (
            <Pagination
              numbers={numbers}
              productsPerPage={productsPerPage}
              qntProducts={productList.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              id={id}
            />
          ) : null}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}
