import React from "react"
import AdminPanel from "../components/AdminPanel"
import Showcase from "../components/Showcase"
import { Helmet } from "react-helmet-async"

export default function Home() {
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Karmen",
    url: "https://karmen.in.ua",
  }
  return (
    <div className="h-full grid grid-cols-1 items-center">
      <Helmet>
        <title>Кармен-оптовый магазин</title>
        <meta
          name="description"
          content="Продаем оптом и мелким оптом, в розницу белье мужское, белье женское,
           женские брюки, велосипедки,гетры, гольфы,заколенки,кальсоны,капри, колготки, колготки теплые,
            колготки с рисунком, колготки детские, колготки женские, лосины,носки мужские, носки детские, носки женские, чулки"
        />
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      </Helmet>

      <AdminPanel />
      <Showcase />
    </div>
  )
}
