import React, { useEffect } from 'react'
import ClientOptionsPrice from './ClientOptionsPrice'

export default function ClientPrice({values,setValues,product}) {
 useEffect(() => {
   // делаем таблицу полосатой

   const elements = document.querySelectorAll(".option")
   elements.forEach((elem, key) => {
     if (key % 2) {
       elem.style.backgroundColor = "#F4F6F6"
     } else {
       elem.style.backgroundColor = "#E5E8E8"
     }
   })
 }, [values])

 const optionValues = JSON.parse(JSON.stringify(values))


 const saveValues = () => {
   setValues(optionValues)
 }

 const level = 0
const crumbs=[]

 return (
   <div className="text-base sm:text-lg">
     <ClientOptionsPrice      
       optionValues={optionValues}
       saveValues={saveValues}
       level={level} 
       product={product}
       crumbs={crumbs}
     />
   </div>
 )
}
