import React, { useState } from 'react'
import styles from '../styles/WaveContainer.module.css'

export default function WaveContainer({ children }) {
  const [isActive, setIsActive] = useState(false)
  
  const handleWave = () => {
    setIsActive(true)
    setTimeout(() => {
      setIsActive(false)
    },500)
  }
  return (
    <div className='relative overflow-hidden' onClick={handleWave}>
      {children}
      <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
      w-0 h-0 bg-blue-500/50 opacity-100 block pointer-events-none
       ${isActive ? styles.active_wave : ''}`}></div>
      </div>
  )
}
