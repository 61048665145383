import React, { useEffect } from "react"

import AdminOptionsPrice from "./AdminOptionsPrice"

export default function AdminPrice({ values, setValues }) {
  useEffect(() => {
    // делаем таблицу полосатой

    const elements = document.querySelectorAll(".option")
    elements.forEach((elem, key) => {
      if (key % 2) {
        elem.style.backgroundColor = "#F4F6F6"
      } else {
        elem.style.backgroundColor = "#E5E8E8"
      }
    })
  }, [values])

  const optionValues = JSON.parse(JSON.stringify(values.optionValues))
  const optionValuesOrigin=optionValues

  const saveValues = () => {
    setValues(prev=>({ ...prev, optionValues }))
  }

  const level = 0
  const crumbs = []

 
  return (
    <div className="text-xl">
      <AdminOptionsPrice
        optionValuesOrigin={optionValuesOrigin}
        optionValues={optionValues}
        saveValues={saveValues}
        level={level}
        crumbs={crumbs}
      />
    </div>
  )
}
