import { Outlet, useNavigate } from "react-router-dom"
import Layout from "./components/Layout"
import { SWRConfig } from "swr"


function App() {
  const navigate=useNavigate()
  return (
     <SWRConfig value={{ onError: (error) => navigate(`/error_server?message=${error.message}`)}}>

       <Layout>
      <Outlet />
    </Layout>
     </SWRConfig>
   
    
  )
}

export default App
