import React from 'react'
import { localStorageService } from '../utils'

export default function Pagination({ currentPage,setCurrentPage,id,numbers }) {  

  const handleClick = (item) => {
    if (!id) return
   localStorageService.update('catalog',{[id]:item})
    setCurrentPage(item)
  }
  
  return (
    <>
     {numbers.length>1 ? (
     <div className='py-1 flex justify-center items-center gap-2 flex-wrap'>
      {numbers.map(item => (
        <div key={item} className={`text-2xl  flex justify-center items-center
         w-10 h-10 border border-blue-500 rounded-full  ${currentPage === item ?
            'bg-sb text-gray-100' : 'bg-inherit text-inherit cursor-pointer hover:bg-gray-50'}`} onClick={()=>handleClick(item)}>
         <p>{item}</p> 
        </div>
      ))}
    </div>
    ):null}
    </>
   
   
  )
}
