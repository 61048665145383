import {  NOIMAGE } from "../config/index"
import { useNavigate } from "react-router-dom"

export default function CatalogsList ({ catalogs = [] }) {
  const navigate = useNavigate()
  return (
    <div className="px-2 gap-5 grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
      {catalogs.length ? (
        catalogs.map((item, i) => (
          <div
            className="justify-self-center text-xl  w-[300px] h-[470px] border
             border-gray-600 flex flex-col gap-1 p-1 shadow-md overflow-hidden
              hover:cursor-pointer hover:border-blue-500 active:scale-[0.98] "
            onClick={() => navigate(`/catalog/${item._id}`)}
            key={i}
          >
            <div className="border border-gray-600 flex items-center justify-center h-[400px] p-1">
              <img
                className="max-w-full max-h-full"
                src={item.imageMd ? `${item.imageMd}` : `${NOIMAGE}`}
                alt={item.name}
              />
            </div>
            <h2 className="flex-1 bg-gray-100 flex items-center justify-center text-xl/5 text-amber-900">
              {item.name}
            </h2>
          </div>
        ))
      ) : (
        <h2>Нет подкаталогов</h2>
      )}
    </div>
  )
}
