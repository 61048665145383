export const API_URL = "https://karmen.in.ua"
export const NOIMAGE = "/noimage.png"
export const PHONE1 = "+38 050 950 16 71"
export const PHONE2 = "+38 098 208 60 83"
export const PHONE3="+38 095 135 58 24"
export const UPOST_IMAGE = "/delivery_logos/upost.png"
export const NEW_POST_IMAGE = "/delivery_logos/new_post.png"
export const AUTOLUX_IMAGE = "/delivery_logos/autolux.png"
export const DELIVERY_IMAGE='/delivery_logos/delivery.png'
export const INFORMATIONS_IMAGES = [
  "/images/informations/about_0.webp",
  "/images/informations/about_1.webp",
  "/images/informations/about_2.webp",
  "/images/informations/about_3.webp",
  "/images/informations/about_4.webp",
  "/images/informations/about_5.webp",
  "/images/informations/about_6.webp",
  "/images/informations/about_7.webp",
  "/images/informations/about_8.webp",
  "/images/informations/about_10.webp",
]
export const CONTACTS_IMAGE = "/images/contacts/image_1.webp"
export const NAVBAR_IMAGE="/images/navbar/about_9.webp"
export const LOGO_NP = '/logo_NP.jpg'
export const LOGO_VF='/logo_vodafone.jpg'
export const LOGO_KS='/logo_ks.jpg'
// production
export const VIBER_BOT_URI='karmen_shop'
export const TELEGRAM_BOT_URI = 'karmen_shop_bot'

// development
// export const VIBER_BOT_URI = "karmenshop"
// export const TELEGRAM_BOT_URI = "karmenshop_bot"

export const NP_API = "c7521ca8867985bf615aa087101f503f"
export const CARRIERS = ['Новая почта']

