import React from 'react'
import { FaTimes } from 'react-icons/fa'



export default function ModalImage({handleUploadChange,setShowImageUpload}) {
  return (   
    <div className='w-[350px] h-[200px] text-gray-700 bg-gray-100 text-lg
        border rounded-md shadow-md flex flex-col items-center justify-start gap-7'>
      <div className='p-1 text-red-500 self-end text-2xl hover:cursor-pointer'
        onClick={() => { setShowImageUpload(false) }}>
          <FaTimes   />
       </div>       
        <p className='text-2xl '>Загрузка изображения</p>
        <input className='text-lg' type="file" onChange={handleUploadChange} />
      </div>
    
  )
}
