
import { Link } from "react-router-dom"
import { FaMinusCircle } from 'react-icons/fa'

export default function AccessDenied() {
  return (
    <div className='flex h-full justify-center items-center flex-col gap-y-5 '>
      <div className='text-[4rem] text-red-500'>
        <FaMinusCircle />
      </div>
      
      <h1>У вас нет прав для просмотра этой страницы</h1>
      <Link className='cursor-pointer text-blue-400' to="/">Вернуться на главную</Link>
    </div>
  )
}
