import React from "react"
import { TailSpin } from "react-loader-spinner"

export default function Spinner() {
  return (
    <>
      <TailSpin
        height="150"
        width="150"
        color="#ABEBC6"
        wrapperStyle={{         
          position: 'fixed',
          top: "calc(40vh",
          left: "calc(50vw - 75px)",
          opacity: "0.8",
        }}
      />
    </>
  )
}
