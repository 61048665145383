import { createContext, useState, useEffect } from "react"
import { localStorageService } from "../utils"

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [isUpload, setIsUpload] = useState(false)
  // isUpload отключает второй useEffect при первой отрисовке
 useEffect(() => {
      setUser(localStorageService.get('user') || {})
   setIsUpload(true)
 }, [])

 
  useEffect(() => {  
    if (isUpload) {     
      localStorageService.set('user',user)
    }    
  }, [user,isUpload])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
