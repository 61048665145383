import React from "react"
import { useGallery, useShowcase } from "../swr"
import GallerySlider from "./GallerySlider"
import CardPopular from "./CardPopular"
import CardNews from "./CardNews"
import Spinner from "./Spinner"

export default function Showcase() {
  const products = useShowcase()

  //  перемешиваем элементы в массиве случайным образом
  if (products) {
    for (let i = products.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = products[i]
      products[i] = products[j]
      products[j] = temp
    }
  }

  const cards = useGallery()

  return (
    <>
      {products && cards ? (
        <div className="">
          {cards ? (
            <GallerySlider products={cards} card={CardNews} title="Новинки" />
          ) : null}
          {products ? (
            <GallerySlider
              products={products}
              card={CardPopular}
              title="Популярное"
            />
          ) : null}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}
