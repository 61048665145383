import React  from "react"
import Links from "../components/Links"
import { useInformation } from "../swr"
import { CONTACTS_IMAGE, PHONE1, PHONE2 } from "../config"
import { Helmet } from "react-helmet-async"
import MapFrame from "../components/MapFrame"

export default function Contacts() {
  const information = useInformation()



  return (
    <>
      <Helmet>
        <title>Контакты магазина</title>
        <meta
          name="description"
          content="Адрес, телефоны, часы работы, положение на карте машазина Кармен"
        />
      </Helmet>
      <div className="w-full h-full flex flex-col gap-2 px-1">
        <Links home={true} back={true}></Links>
        <div className="px-2 basis-5/12 border border-gray-500 rounded  text-xl grid grid-cols-1 xl:grid-cols-[minmax(0,1fr)_1fr] gap-5 justify-items-center items-center">
          <div className=" border border-gray-600 p-1 flex items-center justify-center">
            <img
              className="max-w-full max-h-full"
              src={CONTACTS_IMAGE}
              alt="фото магазина"
            />
          </div>
          <div className="overflow-hidden justify-self-start ">
            <h2 className="font-bold ">Адрес магазина</h2>
            <p>{information ? information.address : null}</p>
            <h2 className="font-bold ">Телефоны</h2>
            <p>{PHONE1}</p>
            <p>{PHONE2}</p>
            <h2 className="font-bold">Режим работы</h2>
            <pre className="text-xl">
              {information ? information.workingHours : null}
            </pre>
          </div>
        </div>
        <div className=" flex-1 min-h-[500px] border border-gray-500 rounded">
          <MapFrame />
        </div>
      </div>
    </>
  )
}
