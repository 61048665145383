import {  NOIMAGE } from "../config"
import  { createPortal } from "react-dom"
import { FaTimes } from "react-icons/fa"

export default function Loupe({ image, setIsShow }) {
  const imageHeight = window.innerHeight * 0.95

  const content = (
    <div className='z-[150] w-full h-full fixed top-0 left-o bg-[rgb(0,0,0,0.9)]'>
      <div className='flex justify-center items-center w-full relative h-screen'>
        <div className='flex items-center max-w-[85%]' onClick={(e) => {
           e.stopPropagation()
          setIsShow(false)
        }}>
          <img
            className="max-w-full cursor-pointer"
            src={image ? `${image}` : `${NOIMAGE}`}
            style={{ maxHeight: imageHeight }}
            alt=''
          />
        </div>
      </div>

      <div
        onClick={(e) => {
          e.stopPropagation()
          setIsShow(false)
        }}
        className='text-white absolute top-3 right-3 text-3xl hover:cursor-pointer'
      >
        <FaTimes />
      </div>
    </div>
  )

  return createPortal(content, document.body)
}
