import { useContext } from "react"
import AuthContext from "../context/AuthContext"
import Links from "../components/Links"
import { useParams } from "react-router-dom"
import { formatingDate, getCurrencySymbol } from "../utils/index"
import { useOrderById } from "../swr"
import { Helmet } from "react-helmet-async"

export default function Order() {
  const { user } = useContext(AuthContext)

  const { id } = useParams()
 
  const order = useOrderById({ id})

  const isOptions = order
    ? order.orderItems.length !== 0 && "options" in order.orderItems[0]
    : false

 
  
  return (
    <>
      <Helmet>
        <title>Заказ</title>
        <meta name="description" content="Страница заказа" />
      </Helmet>
      <div className="px-3">
        <Links home={true} back={true} />
        {order && Object.keys(user).length ? (
          <div className="pt-4 text-lg">
            <h3 className="font-bold text-center">
              Заказ № {order.count} от {formatingDate(order.createdAt)}
            </h3>
            <div className="flex flex-col sm:flex-row ">
              <div className="flex-1">
                <p>
                  <span className="italic font-bold">Имя:</span>{" "}
                  <span>{order.delivery.name}</span>
                </p>
                <p>
                  <span className="italic font-bold">Фамилия:</span>{" "}
                  <span>{order.delivery.surname}</span>
                </p>
                <p>
                  <span className="italic font-bold">Телефон:</span>{" "}
                  <span>{order.delivery.phone}</span>
                </p>
              </div>
              <div className="flex-1">
                <p>
                  <span className="italic font-bold">Город:</span>{" "}
                  <span>{order.delivery.city}</span>
                </p>
                <p>
                  <span className="italic font-bold">Способ доставки:</span>{" "}
                  <span>
                    {order.delivery.pickup
                      ? "самовывоз"
                      : `перевозчик ${order.delivery.carrier} ${order.delivery.branch}`}
                  </span>
                </p>
                <p>
                  <span className="italic font-bold">Способ оплаты:</span>{" "}
                  <span>
                    {order.delivery.prepaid
                      ? "предоплата"
                      : "наложенным платежом"}
                  </span>
                </p>
              </div>
            </div>
            {order.orderItems.length ? (
              <div className="py-3 max-h-[300px] overflow-auto">
                <table className=" border-collapse border border-gray-600 w-full min-w-[768px] table-fixed">
                  <thead>
                    <tr className=" bg-amber-100">
                      <td className="text-center border border-gray-600  p-1">
                        Модель
                      </td>
                      {isOptions
                        ? Object.keys(order.orderItems[0].options).map(
                            (option, i) => (
                              <td
                                className="text-center border border-gray-600  p-1"
                                key={i}
                              >
                                {option}
                              </td>
                            )
                          )
                        : null}
                      <td className="text-center border border-gray-600  p-1 w-[100px]">
                        Цена
                      </td>
                      <td className="text-center border border-gray-600  p-1 w-[100px]">
                        Кол-во
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {order.orderItems.map((item, i) => (
                      <tr key={i}>
                        <td className="text-center border border-gray-600 p-1">
                          {item.name}
                        </td>
                        {isOptions && Object.keys(item.options).length
                          ? Object.keys(item.options).map((opt, j) => (
                              <td
                                className="text-center border border-gray-600 p-1"
                                key={j}
                              >
                                {item.options[opt]}
                              </td>
                            ))
                          : null}
                        <td className="text-center border border-gray-600 p-1">
                          {item.price} {getCurrencySymbol(item.currencyValue)}
                        </td>
                        <td className="text-center border border-gray-600 p-1 ">
                          {item.qnt}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        className=" bg-emerald-50"
                        colSpan={
                          (isOptions
                            ? Object.keys(order.orderItems[0].options).length
                            : 0) + 3
                        }
                      >
                        <div className="flex items-center justify-between px-2">
                          <p>Всего товаров: {order.totalQnt}</p>
                          <p>Сумма:{order.totalAmount}</p>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}
