import AccessDenied from "../components/AccessDenied"
import AuthContext from "../context/AuthContext"
import { useContext, useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import {  NOIMAGE } from "../config/index"
import "react-toastify/dist/ReactToastify.css"
import Links from "../components/Links"
import { getListForCatalogsMenu, idToString } from "../utils/index"
import ModalImage from "../components/ModalImage"

import { FaImage, FaSave, FaTimes, FaWindowClose } from "react-icons/fa"
import ModalDialog from "../components/ModalDialog"
import { useCatalogs, useCatalogsId } from "../swr"
import { useSWRConfig } from "swr"
import { saveImage } from "../handleImage"
import { Helmet } from "react-helmet-async"

export default function EditCatalog() {
  const { id } = useParams()
  const { mutate } = useSWRConfig()
  const navigate = useNavigate()
  const {
    user: { isAdmin },
  } = useContext(AuthContext)

  const catalogs = useCatalogs()
  const catalog = useCatalogsId(id)

  const [values, setValues] = useState({
    _id: null,
    name: "",
    parent: "",
    parentId: null,
  })

  const [image, setImage] = useState({
    pathMd: "",
    pathSm: "",
  })

  const [showImageUpload, setShowImageUpload] = useState(false)

  const listForMenu = catalogs ? getListForCatalogsMenu(catalogs) : []

  useEffect(() => {
    if (!catalog || !catalogs) return
    const catalogParent = catalogs.find(
      (item) => idToString(item._id) === idToString(catalog.parentId)
    )

    setValues({
      _id: catalog._id,
      name: catalog.name,
      parent: catalogParent ? catalogParent.name : "",
      parentId: catalog.parentId,
    })
    if (catalog.imageMd) {
      setImage({ pathMd: catalog.imageMd, pathSm: catalog.imageSm })
    }
  }, [catalog, catalogs])

  const handleSave = async (e) => {
    e.preventDefault()
    // Проверка на заполнение поля имени категории
    const hasEmptyFields = !values.name.trim()
    if (hasEmptyFields) {
      toast.error("Поле Каталог должно быть заполнено")
      return
    }

    if (values._id === values.parentId) {
      toast.error("Каталог не может быть родителем самого себя")
      setValues(prev=>({ ...prev, parentId: null, parent: "" }))

      return
    }

    // Send data
    const res = await fetch(`/api/catalogs`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"       
      },
      body: JSON.stringify({ ...values, image }),
    })
    const data = await res.json()

    if (!res.ok) {
      toast.error(data.message)
    } else {
      mutate(`/api/catalogs`)
      navigate("/")
    }
  }

  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target

    setValues({ ...values, [name]: value })
  }
  const handleUploadChange = async (e) => {
    const file = e.target.files[0]
    try {
       const { pathMd, pathSm } = await saveImage({ file })
    setImage({ pathMd, pathSm })
    setShowImageUpload(false)
    } catch (error) {
      toast.error(error.message)
    }
   
  }

  const handleListClick = async ({ id, name }) => {
    // проверяем наличие товаров в родительском каталоге
    const res = await fetch(`/api/products/catalog?catalogId=${id}`)
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    } else {
      const { products } = data
      if (products.length) {
        toast.warning("В этом каталоге есть товары, выберите другой каталог")
        return
      }
      setValues({ ...values, parent: name, parentId: id })
    }
  }

  const deleteImage = () => {
    setImage({ pathMd: "", pathSm: "" })
  }

  return (
    <>
      <Helmet>
        <title>Редактировать каталог</title>
        <meta
          name="description"
          content="Редактирование каталога в машазине Кармен"
        />
      </Helmet>
      {!isAdmin ? (
        <AccessDenied />
      ) : (
        <div className="px-2 text-lg">
          <ToastContainer />
          <div className="block">
            <Links home={true} back={true}>
              <div className="flex justify-end items-center py-1">
                <FaSave
                  className="text-sb text-3xl hover:cursor-pointer hover:text-sb-dark"
                  title="Сохранить"
                  onClick={handleSave}
                />
              </div>
            </Links>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 pt-4">
              <div className="justify-self-center w-[300px]">
                <div
                  className="w-[300px] h-[400px] flex justify-center items-center
                 border border-gray-700 p-1"
                >
                  <img
                    className="max-w-full max-h-full"
                    src={image.pathMd ? `${image.pathMd}` : NOIMAGE}
                    alt=""
                  />
                </div>

                <div className="flex text-3xl justify-around w-full my-2">
                  <FaImage
                    className="flex cursor-pointer  text-sb"
                    onClick={() => {
                      setShowImageUpload(true)
                    }}
                    name="save"
                    title="Сохранить"
                  />
                  <FaWindowClose
                    className="flex cursor-pointer text-red-500"
                    onClick={deleteImage}
                    name="delete"
                    title="Удалить"
                  />
                </div>
              </div>
              <div>
                <input
                  className="block w-full h-8 mb-5 px-2 py-1 text-lg"
                  type="text"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Каталог"
                />

                <div className="relative group">
                  <input
                    className="block w-full h-8 mb-5 px-2 py-1 text-lg"
                    type="text"
                    id="parent"
                    name="parent"
                    value={values.parent}
                    onChange={handleChange}
                    placeholder="Родительский каталог"
                    readOnly
                    autoComplete="off"
                  />
                  <FaTimes
                    className="block -translate-x-1/2 -translate-y-1/2 text-5
                       absolute text-red-500 top-1/2 right-0 hover:cursor-pointer"
                    onClick={() =>
                      setValues({
                        ...values,
                        parent: "",
                        parentId: null,
                      })
                    }
                  />
                  <ul className="droplist_wrapper group-hover:block">
                    {listForMenu && (
                      <div className=" droplist">
                        {listForMenu.map((item, i) => (
                          <li
                            className="px-3 hover:cursor-pointer hover:bg-gray-100"
                            key={i}
                            onClick={() =>
                              handleListClick({
                                id: item.cat._id,
                                name: item.cat.name,
                              })
                            }
                          >
                            {item.tree}
                          </li>
                        ))}
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showImageUpload ? (
        <ModalDialog>
          <ModalImage
            handleUploadChange={handleUploadChange}
            setShowImageUpload={setShowImageUpload}
          />
        </ModalDialog>
      ) : null}
    </>
  )
}
